import React, { Fragment } from 'react'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"

import Menu2 from "../components/menu2"
import Footer from "../components/footer"


// markup
export default function Example() {
  return (
    <>
    <Helmet title="FACE - Our Team"/>
    <Menu2 />

    <main>
        <section className="face-gray-bg overflow-hidden" id="dale">
          <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
            <svg
              className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
            </svg>

            <svg
              className="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="56409614-3d62-4985-9a10-7ca758a8f4f0"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)" />
            </svg>

            <div className="relative lg:flex lg:items-center">
              <div className="hidden lg:block lg:flex-shrink-0">
                <StaticImage
                  class="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                  src="../images/dale-pinkert.png"
                  alt="dale avatar"
                  width={200}
                  height={200}
                />
              </div>

              <div className="relative lg:ml-10">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-indigo-200 opacity-50"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 144 144"
                  aria-hidden="true"
                >
                  <path
                    strokeWidth={2}
                    d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                  />
                </svg>
                <blockquote className="relative">
                  <div className="text-xl leading-9 font-medium text-gray-900">
                    <p>
                    Dale began his career in operations on the CME floor for Dean Witter when they traded currency futures on chalkboards. He became a licensed Series 3 broker in 1976 and went on to own and operate Pinkert Commodities GIB. He became a Member of the CME (IOM) Division for a stint and his forecasts have been aired on many Financial media including CNBC.
                    </p>
                    <p>
                    Dale has Coached/Mentored retail and prop traders and has gained solid reputation for his work on the other side of the mic having interviewed over 700 of the best of the best in Trading.
                    </p>
                  </div>
                  <footer className="mt-8">
                    <div className="flex">
                      <div className="flex-shrink-0 lg:hidden">

                        <StaticImage
                          className="h-12 w-12 rounded-full"
                          src="../images/dale-pinkert.png"
                          alt="Dale avatar"
                          width={200}
                          height={200}
                        />
                      </div>
                      <div className="ml-4 lg:ml-0">
                        <div className="text-base font-medium text-gray-900">Dale Pinkert</div>
                        <div className="text-base font-medium face-blue">Trading Coach</div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white overflow-hidden" id="blake">
          <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
            <svg
              className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
            </svg>

            <svg
              className="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="56409614-3d62-4985-9a10-7ca758a8f4f0"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)" />
            </svg>

            <div className="relative lg:flex lg:items-center">
              <div className="hidden lg:block lg:flex-shrink-0">
                <StaticImage
                  class="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                  src="../images/blake-morrow.png"
                  alt="Blake avatar"
                  width={200}
                  height={200}
                />
              </div>

              <div className="relative lg:ml-10">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-indigo-200 opacity-50"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 144 144"
                  aria-hidden="true"
                >
                  <path
                    strokeWidth={2}
                    d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                  />
                </svg>
                <blockquote className="relative">
                  <div className="text-xl leading-9 font-medium text-gray-900">
                    <p>Blake Morrow spent most of his professional career as the Chief Currency Strategist for Wizetrade group for 15 years, and then the Senior Currency Strategist for Ally Financial after the acquisition of Tradeking which owned the Wizetrade Group. Blake has over 20 years of trading experience and has been a co-owner of a Dallas based brokerage firm and LiquidTrader Technologies. Blake spent over 14 years, hosting a popular Forex and Trading online show, “The Morning EDGE” where he spent a majority of his day trading with and teaching other traders to navigate through the markets. Blake is a seasoned individual investor in equities and Forex.
                    <br/>
                    <br/>
                    Blake is a husband, father and avid golf hacker. Proud Marine Infantryman 3rd Battalion 9th Marines 1990-1994.
                    </p>
                  </div>
                  <footer className="mt-8">
                    <div className="flex">
                      <div className="flex-shrink-0 lg:hidden">

                        <StaticImage
                          className="h-12 w-12 rounded-full"
                          src="../images/blake-morrow.png"
                          alt="Blake avatar"
                          width={200}
                          height={200}
                        />
                      </div>
                      <div className="ml-4 lg:ml-0">
                        <div className="text-base font-medium text-gray-900">Blake Morrow</div>
                        <div className="text-base font-medium face-blue">Co-Founder and CEO at ForexAnalytix</div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </section>

        <section className="face-gray-bg overflow-hidden" id="steve">
          <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
            <svg
              className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
            </svg>

            <svg
              className="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="56409614-3d62-4985-9a10-7ca758a8f4f0"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)" />
            </svg>

            <div className="relative lg:flex lg:items-center">
              <div className="hidden lg:block lg:flex-shrink-0">
                <StaticImage
                  class="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                  src="../images/steve-voulgaridis.png"
                  alt="Steve avatar"
                  width={200}
                  height={200}
                />
              </div>

              <div className="relative lg:ml-10">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-indigo-200 opacity-50"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 144 144"
                  aria-hidden="true"
                >
                  <path
                    strokeWidth={2}
                    d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                  />
                </svg>
                <blockquote className="relative">
                  <div className="text-xl leading-9 font-medium text-gray-900">
                    <p>
                    Steve Voulgaridis’s academic studies include a bachelor degree in Business Administration and one in Finance. Steve has been actively engaged with the world of Finance and the financial markets since 2005. He started his career as an individual trader managing personal and family funds and within a few years progressed to become a consultant and trader for a private fund.
                    <br/>
                    <br/>
                    Due to his educational background, Steve uses Macroeconomic theory and the study of fundamentals, to build a top – down, long term view of the markets. Additionally, the tools of trade he uses to analyze their daily movements are comprised of Classical Chart Patterns, Candlestick Patterns, Fibonacci and Elliott Wave Theory.
                    <br/>
                    <br/>
                    Steve Voulgaridis is an avid lover of sciences (with economics being the prominent one in his heart) and has always dedicated part of his free time studying them. He likes to spend the rest of his free time doing sports (tennis, basketball, football, sailing), socializing with friends and travelling.
                    <br/>
                    <br/>
                    Steve is happily married since 2014 with 1 baby boy and 2 feline companions (cats) completing his household family.
                    </p>
                  </div>


                  <footer className="mt-8">
                    <div className="flex">
                      <div className="flex-shrink-0 lg:hidden">

                        <StaticImage
                          className="h-12 w-12 rounded-full"
                          src="../images/steve-voulgaridis.png"
                          alt="Steve avatar"
                          width={200}
                          height={200}
                        />
                      </div>
                      <div className="ml-4 lg:ml-0">
                        <div className="text-base font-medium text-gray-900">Steve Voulgaridis</div>
                        <div className="text-base font-medium face-blue">Co-Founder & C.O.O. at ForexAnalytix</div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white overflow-hidden" id="ryan">
          <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
            <svg
              className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
            </svg>

            <svg
              className="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="56409614-3d62-4985-9a10-7ca758a8f4f0"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)" />
            </svg>

            <div className="relative lg:flex lg:items-center">
              <div className="hidden lg:block lg:flex-shrink-0">
                <StaticImage
                  class="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                  src="../images/ryan-littlestone.png"
                  alt="Ryan avatar"
                  width={200}
                  height={200}
                />
              </div>

              <div className="relative lg:ml-10">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-indigo-200 opacity-50"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 144 144"
                  aria-hidden="true"
                >
                  <path
                    strokeWidth={2}
                    d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                  />
                </svg>
                <blockquote className="relative">
                  <div className="text-xl leading-9 font-medium text-gray-900">
                    <p>
                    Where did it all begin?<br/>
                    I entered the financial world bright-eyed and bushy-tailed over 30 years ago, when I stepped out of Bank station in the heart of London to begin my career at LME founding member Rudolf Wolff. I worked my way from the back office, to dealing in LME warrants, and then to the main execution desk broking orders for customers across the LME and virtually every major global market.
                    </p>
                    <p>
                    When did you start trading?<br/>
                    While at Wolff, an opportunity to help set up a new LIFFE operation was too good to turn down, and it wasn’t long before the pull of the pits had me hooked and I became a “local” trading my own money. I remember my first day like it was yesterday. I stood in the pit and didn’t have the foggiest idea what to do. My first major trading lesson was realising that even with years of being involved in markets, it does not prepare you for trading with your own money. I had to learn, and learn quickly but I was very fortunate to have some very good trading teachers, and here I am, still trading my own account and managing to win more than I lose. What I really want to do now is pass on the knowledge and experience I gained, to help you realise your potential.
                    </p>
                    <p>
                    What is your most important trading rule?<br/>
                    “Trading is not about learning how to win, it’s about learning how not to lose”.
                    </p>
                  </div>


                  <footer className="mt-8">
                    <div className="flex">
                      <div className="flex-shrink-0 lg:hidden">

                        <StaticImage
                          className="h-12 w-12 rounded-full"
                          src="../images/ryan-littlestone.png"
                          alt="Ryan avatar"
                          width={200}
                          height={200}
                        />
                      </div>
                      <div className="ml-4 lg:ml-0">
                        <div className="text-base font-medium text-gray-900">Ryan Littlestone</div>
                        <div className="text-base font-medium face-blue">Managing Director, trader and analyst at ForexFlow.live</div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </section>

        <section className="face-gray-bg overflow-hidden" id="stelios">
          <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
            <svg
              className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
            </svg>

            <svg
              className="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="56409614-3d62-4985-9a10-7ca758a8f4f0"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)" />
            </svg>

            <div className="relative lg:flex lg:items-center">
              <div className="hidden lg:block lg:flex-shrink-0">
                <StaticImage
                  class="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                  src="../images/stelios-kontogoulas.png"
                  alt="Stelios avatar"
                  width={200}
                  height={200}
                />
              </div>

              <div className="relative lg:ml-10">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-indigo-200 opacity-50"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 144 144"
                  aria-hidden="true"
                >
                  <path
                    strokeWidth={2}
                    d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                  />
                </svg>
                <blockquote className="relative">
                  <div className="text-xl leading-9 font-medium text-gray-900">
                    <p>
                    Stelios Kontogoulas was fascinated by computers from a very young age and went on to study Computer Science at Imperial College. Following a 5-year IT consulting career (having worked for Accenture among others), he felt unfulfilled and wanted to make a change. He completed an MBA and during that time – via a summer internship – he was exposed to the world of trading. He instantly knew that this is what he wanted to do for the rest of his career. Stelios spent the next 10 busy years working for two major global banks in the City of London as an interest rates market-maker.
                    </p>
                    <p>
                    In 2012 Stelios Kontogoulas decided to start a family with his wife and they both moved back home to Greece. Since then Stelios has been trading his family portfolio – mainly FX but also most other asset classes. His trading decisions are mostly driven by fundamentals and are very rarely short-term. He uses technicals for entry/exit points and is a loyal follower of basic technical analysis and Fibonacci.
                    </p>
                    <p>
                    Aside from trading and economics, Stelios spends most of his time with his wife, his two young daughters and his dogs. He is a devoted pool & snooker player and also loves to windsurf and play poker.
                    </p>
                  </div>


                  <footer className="mt-8">
                    <div className="flex">
                      <div className="flex-shrink-0 lg:hidden">

                        <StaticImage
                          className="h-12 w-12 rounded-full"
                          src="../images/stelios-kontogoulas.png"
                          alt="Stelios avatar"
                          width={200}
                          height={200}
                        />
                      </div>
                      <div className="ml-4 lg:ml-0">
                        <div className="text-base font-medium text-gray-900">Stelios Kontogoulas</div>
                        <div className="text-base font-medium face-blue">Co-founder at Forex Analytix</div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white overflow-hidden" id="koen">
          <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
            <svg
              className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
            </svg>

            <svg
              className="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="56409614-3d62-4985-9a10-7ca758a8f4f0"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)" />
            </svg>

            <div className="relative lg:flex lg:items-center">
              <div className="hidden lg:block lg:flex-shrink-0">
                <StaticImage
                  class="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                  src="../images/koen-vanderschrick.png"
                  alt="Koen avatar"
                  width={200}
                  height={200}
                />
              </div>

              <div className="relative lg:ml-10">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-indigo-200 opacity-50"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 144 144"
                  aria-hidden="true"
                >
                  <path
                    strokeWidth={2}
                    d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                  />
                </svg>
                <blockquote className="relative">
                  <div className="text-xl leading-9 font-medium text-gray-900">
                    <p>
                    K-man has 40 years of financial markets experience, the bulk as a spot FX market maker at world leading commercial banks. Started his career in his homeland Belgium as a trainee at the local branch of a US bank. Then gradually moved through the ranks, making markets in mainly G10 currencies through high tides and crises to central banks, biggest corporates and asset managers and most furious at times hedge funds. The last 17 years of his bank career were spent making a market in JPY from Paris, London and finally Tokyo, considered his second home country, as head of FX and having a trading book in FX cash and options. He is trading his own account since 2016, focussing on how to marry fundamentals with KISS (keep it simple, stupid) techs, likes a nicely coloured fib chart and still trading some actively managed options.
                    </p>
                    <p>
                    "Embrace change, adapt" is his trading motto.
                    </p>
                  </div>


                  <footer className="mt-8">
                    <div className="flex">
                      <div className="flex-shrink-0 lg:hidden">

                        <StaticImage
                          className="h-12 w-12 rounded-full"
                          src="../images/koen-vanderschrick.png"
                          alt="Koen avatar"
                          width={200}
                          height={200}
                        />
                      </div>
                      <div className="ml-4 lg:ml-0">
                        <div className="text-base font-medium text-gray-900">Κoen Vanderschrick</div>
                        <div className="text-base font-medium face-blue">Foreign Exchange Manager at Independent Forex Trader</div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </section>

        <section className="face-gray-bg overflow-hidden" id="grega">
          <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
            <svg
              className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
            </svg>

            <svg
              className="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="56409614-3d62-4985-9a10-7ca758a8f4f0"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)" />
            </svg>

            <div className="relative lg:flex lg:items-center">
              <div className="hidden lg:block lg:flex-shrink-0">
                <StaticImage
                  class="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                  src="../images/grega.png"
                  alt="Grega avatar"
                  width={200}
                  height={200}
                />
              </div>

              <div className="relative lg:ml-10">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-indigo-200 opacity-50"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 144 144"
                  aria-hidden="true"
                >
                  <path
                    strokeWidth={2}
                    d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                  />
                </svg>
                <blockquote className="relative">
                  <div className="text-xl leading-9 font-medium text-gray-900">
                    <p>
                    Grega Horvat is based in Slovenia and has been in Forex market since 2003. Most of the Elliott Wave labeled charts that you can find on our website is his work. His approach to the markets is mainly technical. He uses a lot of different methods when analyzing the markets such as candlestick patterns, MA, technical indicators etc. His specialty however is Elliott Wave Theory which could be very helpful especially if you know how to use it in combination with other tools/indicators.
                    </p>
                    <p>
                    He is the owner of www.ew-forecast.com, but before that he was working for Capital Forex Group and TheLFB.com. His feature articles have been published on: FXstreet.com, Thestreet.com, Action forex, Forex TV, Istockanalyst, ForexFactory, Fxtraders.eu, Insidefutures.com, etc. He recently won the award on FXStreet.com for Best Forex Analysis in 2016.
                    </p>

                  </div>


                  <footer className="mt-8">
                    <div className="flex">
                      <div className="flex-shrink-0 lg:hidden">

                        <StaticImage
                          className="h-12 w-12 rounded-full"
                          src="../images/grega.png"
                          alt="Grega avatar"
                          width={200}
                          height={200}
                        />
                      </div>
                      <div className="ml-4 lg:ml-0">
                        <div className="text-base font-medium text-gray-900">Grega Horvat</div>
                        <div className="text-base font-medium face-blue">FX, commodities, stocks, crypto, metals</div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white overflow-hidden" id="andre">
          <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
            <svg
              className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
            </svg>

            <svg
              className="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="56409614-3d62-4985-9a10-7ca758a8f4f0"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)" />
            </svg>

            <div className="relative lg:flex lg:items-center">
              <div className="hidden lg:block lg:flex-shrink-0">
                <StaticImage
                  class="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                  src="../images/andre-cardoso.png"
                  alt="Andre avatar"
                  width={200}
                  height={200}
                />
              </div>

              <div className="relative lg:ml-10">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-indigo-200 opacity-50"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 144 144"
                  aria-hidden="true"
                >
                  <path
                    strokeWidth={2}
                    d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                  />
                </svg>
                <blockquote className="relative">
                  <div className="text-xl leading-9 font-medium text-gray-900">
                    <p>
                    Andre Cardoso worked for a few technology companies as a NOC supervisor and Web Channel Manager for nearly 15 years. During that time, he developed an interest in the financial markets and specifically technical analysis. His excitement and continuous learning eventually introduced him to Harmonics and that quickly became his passion and field of expertise.
                    </p>
                    <p>
                    During the subsequent years he gained extensive experience trading and gradually formulated his own trading strategy. Today his own technical approach allows him to trade as an independent global market analyst and signal provider with an interesting group of followers in some of the largest trading networks such as TradingView and StockTwits.
                    </p>
                    <p>
                    André Cardoso is a proud father and lives in Porto – Portugal.
                    </p>
                  </div>


                  <footer className="mt-8">
                    <div className="flex">
                      <div className="flex-shrink-0 lg:hidden">

                        <StaticImage
                          className="h-12 w-12 rounded-full"
                          src="../images/andre-cardoso.png"
                          alt="Andre avatar"
                          width={200}
                          height={200}
                        />
                      </div>
                      <div className="ml-4 lg:ml-0">
                        <div className="text-base font-medium text-gray-900">André Cardoso</div>
                        <div className="text-base font-medium face-blue">Financial Services</div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="face-gray-bg overflow-hidden" id="paul">
          <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
            <svg
              className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
            </svg>

            <svg
              className="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="56409614-3d62-4985-9a10-7ca758a8f4f0"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)" />
            </svg>

            <div className="relative lg:flex lg:items-center">
              <div className="hidden lg:block lg:flex-shrink-0">
                <StaticImage
                  class="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                  src="../images/paul-franco.png"
                  alt="Franco avatar"
                  width={200}
                  height={200}
                />
              </div>

              <div className="relative lg:ml-10">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-indigo-200 opacity-50"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 144 144"
                  aria-hidden="true"
                >
                  <path
                    strokeWidth={2}
                    d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                  />
                </svg>
                <blockquote className="relative">
                  <div className="text-xl leading-9 font-medium text-gray-900">
                    <p>
                    Paul is based in San Antonio, Texas and has been trading forex since 2009. His approach is from the technical side with a focus on intraday levels using fibonacci and price zones along with exponential moving averages for momentum. He incorporates the news to set the tone for the day, and week. He primarily trades the Fx majors, gold, and Bonds.
                    </p>
                    <p>
                    He has hosted a daily forex webinar for the last few years (European Crossover, and the Morning Edge) assisting traders with intra-day analysis. Paul's interests are playing guitar and painting.
                    </p>
                  </div>


                  <footer className="mt-8">
                    <div className="flex">
                      <div className="flex-shrink-0 lg:hidden">

                        <StaticImage
                          className="h-12 w-12 rounded-full"
                          src="../images/paul-franco.png"
                          alt="Franco avatar"
                          width={200}
                          height={200}
                        />
                      </div>
                      <div className="ml-4 lg:ml-0">
                        <div className="text-base font-medium text-gray-900">Paul Franco</div>
                        <div className="text-base font-medium face-blue">Fx majors, gold, and Bonds trader</div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </section> */}
    </main>
    <Footer />
    </>
  )
}
